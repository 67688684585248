<template>
  <div class="text-right">
  </div>
</template>

<script>
import $ from "jquery";
import { Toast } from "vant";
import axios from "axios";
export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      userinfodata: null,
      total: null,
      familyLevelConfig: null,
      token: null,
      uid: null,
      headers: null,
    };
  },
  created() {
    document.getElementsByTagName("html")[0].style.backgroundColor = "#ffffff";
    document.getElementsByTagName("html")[0].style.padding = "0 16px 0 16px";

    // this.familyLevel()
    document.title = this.$t('slide1.familyDescriptiontitle')
  },
  mounted() {
    // document.getElementsByTagName("html")[0].style.backgroundColor = "#f6f7fb";
    // document.getElementsByTagName("body")[0].style.backgroundColor = "#f6f7fb";
    //调用原生方法
    this.$completes.complete("getUserInfo");
    //原生调用h5方法
    window.getUserInfo = this.getUserInfo
    window['getUserInfo'] = (data) => {
      this.getUserInfo(data);
    }
  },

  methods: {

    //原生返回参数
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.headers = [
        'Content-Type:' + 'application/json; charset=utf-8',
        'deviceId:' + JSON.parse(data).deviceId,
        'lang:' + JSON.parse(data).lang,
        'version:' + JSON.parse(data).version,
        'os:' + JSON.parse(data).os,
        'model:' + JSON.parse(data).model,
        'fid:' + JSON.parse(data).fid
      ]
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfo.lang.substring(0, 2)
      let lang = this.userInfo.lang
      if (langs == 'zh') {
        if (lang == 'zh_TW' || lang == 'zh_HK') {
          this.$i18n.locale = 'tc'
        }
        else {
          this.$i18n.locale = 'zh'
        }
      }
      else if (langs == 'en') {
        this.$i18n.locale = 'en'
      }
      else if (langs == 'ar') {
        this.$i18n.locale = 'ar'
      }
      else if (langs == 'tr') {
        this.$i18n.locale = 'tr'
      }
      else if (langs == 'pt') {
        this.$i18n.locale = 'pt'
      }
      else if (langs == 'es') {
        this.$i18n.locale = 'es'
      }
      else {
        this.$i18n.locale = 'en'
      }
      document.querySelector('html').setAttribute('lang', this.$i18n.locale);
      document.title = this.$t('slide1.familyDescriptiontitle')
      this.familyLevel()

    },
    familyLevel() {
      let that = this
      let getFamilyLevelConfig = {
        option: 'get',
        host: this.$serviceIp + '/api/code/family/getFamilyLevelConfig?token=' + that.token + '&uid=' + that.uid,
        data: '',
        header: JSON.stringify(that.headers),
      };
      this.$server.curlExec(getFamilyLevelConfig).then((data) => {
        if (data.code == 200) {
          that.familyLevelConfig = data.data
        }
      });
    },


  },

};

</script>

<style scoped>
/* html[lang="ar"] .text-right {
  text-align: right;
}

html[lang="zh"] .text-left {
  text-align: left;
} */
html[lang="ar"] .content {
  font-size: 0.237rem;
  font-family: mediums;
  font-weight: 400;
  color: #707480;
  line-height: 0.338rem;
  padding-top: 0.25rem;
  direction: rtl;
}

html[lang="ar"] .title {
  font-size: 0.28rem;
  font-family: mediums;
  font-weight: 500;
  color: #000000;
  line-height: 0.27rem;
  direction: rtl;
  text-indent: 0;
  margin-left: 0;
}

.title {
  font-size: 0.28rem;
  font-family: mediums;
  font-weight: 500;
  color: #000000;
  line-height: 0.27rem;
  text-indent: -0.338rem;
  margin-left: 0.338rem;
}

.content {
  font-size: 0.22rem;
  font-family: Regular;
  font-weight: 400;
  color: #707480;
  line-height: 0.338rem;
  padding-top: 0.25rem;
}

.content > p {
  margin: 0 !important;
}

.biaotou {
  width: 100%;
  height: 0.93rem;
  background: #5dc48a;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0.25rem 0;
}

.biaotou > .titles {
  font-size: 0.23rem;
  font-family: mediums;
  font-weight: 500;
  color: #ffffff;
  width: 3rem;
  padding-left: 0.1rem;
  text-align: center;
}

.biaot_content {
  width: 100%;
  padding-bottom: 0.355rem;
  display: flex;
  align-items: center;
  justify-content: space-around;

  font-size: 0.23rem;
  font-family: Regular;
  font-weight: 400;
  color: #666666;
}

.expand {
}
</style>